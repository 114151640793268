import React from 'react'
import Layout from '../components/layout/layout'
import MobileLayout from '../components/layout/mobileLayout'
import SEO from '../components/layout/seo'
import RecommendedLinks from '../components/shared/recommendedLinks'
import PageTitle from '../components/shared/pageTitle'
import { useBP } from '../data/objects/breakpoints'
import { renderOnMount } from '../components/forceUpdate'
import TrustPilotSection from '../components/reviews/TrustPilotSection'
import Testimonials from '../components/reviews/testimonials'
import ExpertsReviews from '../components/reviews/expertsReviews'
import SuccessStories from '../components/reviews/successStories'

const pageForGAEvents = 'Reviews'

const Reviews = () => {
  const renderKey = renderOnMount()
  const { fitsB } = useBP().get()
  const metaDesc =
    'Read customer reviews of Worthy.com and see what they are saying about their 5-star experience selling their jewelry through our online platform.'
  const main = (
    <>
      <SEO title="Worthy.com Reviews - Sell Your Diamonds and Jewelry " description={metaDesc} />
      <PageTitle
        title="Worthy Reviews & Testimonials"
        subTitle="Our sellers are very vocal about their positive experiences with us. See what they have to say as you prepare to start your jewelry selling journey."
      />
      <TrustPilotSection />
      <ExpertsReviews />
      <Testimonials />
      <SuccessStories />
      <RecommendedLinks
        relevantLinks={['FAQs', 'How it works', 'Recently sold']}
        page={pageForGAEvents}
        dataAutomationPrefix="Reviews"
      />
    </>
  )

  return fitsB ? (
    <MobileLayout key={renderKey} page={pageForGAEvents}>
      {main}
    </MobileLayout>
  ) : (
    <Layout key={renderKey} page={pageForGAEvents}>
      {main}
    </Layout>
  )
}
export default Reviews
