import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import common from '../../styles/common.module.css'
import { H2asH1, RunningText } from '../../styles/styled/commonText'
import { BackgroundSection } from '../commonStyled/sectionsWithBackground'
import bgPattern from '../../images/HIW/HIW_pattern.svg'
import {
  SpacingSizes,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  LineHeights,
} from '../../../clarity'
import { to, from, size } from '../../styles/constants'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import { envUrls } from '../../data/objects/env'
import { GA } from '../../data/GA'

export interface Story {
  text: string
  readMoreLink: string
  picture: string
}

const storiesData: Array<Story> = [
  {
    text: 'Parting Ways With Jewelry From a Broken Marriage',
    readMoreLink: `${envUrls.site}/about/reviews/parting-ways-with-jewelry-from-a-broken-marriage`,
    picture: 'JewelryBox',
  },
  {
    text: 'A Diamond Helps Pay For College',
    readMoreLink: `${envUrls.site}/about/reviews/divorced-couple-used-a-diamond-to-help-pay-for-college`,
    picture: 'GraduationHats',
  },
  {
    text: 'How Selling My Diamond Ring Helped Me Move On',
    readMoreLink: `${envUrls.site}/about/reviews/how-selling-a-diamond-ring-helped-this-divorced-woman-move-forward`,
    picture: 'WindowCoffee',
  },
  {
    text: 'A Roger Dubuis Watch & Nothing To Lose',
    readMoreLink: `${envUrls.site}/about/reviews/a-roger-dubuis-watch-nothing-to-lose`,
    picture: 'Watch',
  },
  {
    text: 'Grandmother’s Ring Buys Horse For Granddaughter',
    readMoreLink: `${envUrls.site}/about/reviews/grandmothers-ring-buys-horse`,
    picture: 'DiamondHorse',
  },
  {
    text: 'Celebrity Client Sells Ring With Movie Memories',
    readMoreLink: `${envUrls.site}/about/reviews/celebrity-client-sells-ring-with-movie-memories`,
    picture: 'RingBox',
  },
]

const SectionBackground = styled(BackgroundSection)`
  padding: ${SpacingSizes.xxl} ${SpacingSizes.md};
  @media ${to.bpB} {
    padding: ${SpacingSizes.xxl} 0 ${SpacingSizes.xxl} 0;
  }
`

const SectionContainer = styled.div`
  max-width: ${size.e};

  @media ${to.bpB} {
    width: 100%;
    align-items: flex-start;
  }
`

const StoriesContainer = fadeUpWrapper(styled.div`
  gap: ${SpacingSizes.md};

  @media ${from.bpC} {
    max-width: ${size.e};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${to.bpC} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr) 1px;
    overflow-x: auto;
    padding: 0 ${SpacingSizes.smd};

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
  }
`)

const StoriesTitle = fadeUpWrapper(styled(H2asH1)`
  line-height: ${LineHeights[54]}; // make sure this override is neccesarry with design
  margin-bottom: ${SpacingSizes.xl};

  @media ${to.bpB} {
    padding: 0px ${SpacingSizes.smd};
  }
`)

const StoryCard = styled.div`
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};
  font-size: 0px;
  line-height: 0px;

  @media ${to.bpC} {
    width: 260px;
  }
`

const TextContainer = styled.div`
  padding: ${SpacingSizes.xs} ${SpacingSizes.smd} ${SpacingSizes.sm} ${SpacingSizes.smd};
`

const StoryTitle = styled(RunningText)`
  @media ${to.bpB} {
    font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_18]};
    line-height: ${LineHeights[30]};
  }
`

const ReadMore = styled.div`
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_700_main]};
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_14_quarterbold]};
  line-height: ${LineHeights[24]};
  @media ${to.bpB} {
    padding-top: ${SpacingSizes.xs};
    font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16_quarterbold]};
    line-height: ${LineHeights[24]};
  }
`

const SuccessStories = () => {
  const qData = useStaticQuery(graphql`
    fragment successStory on File {
      childImageSharp {
        fluid(maxWidth: 442) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query SuccessStoryImageList {
      JewelryBox: file(relativePath: { eq: "reviews/stories/jewelry_box.jpg" }) {
        ...successStory
      }
      GraduationHats: file(relativePath: { eq: "reviews/stories/graduation_hats.jpg" }) {
        ...successStory
      }
      WindowCoffee: file(relativePath: { eq: "reviews/stories/window_coffee.jpg" }) {
        ...successStory
      }
      Watch: file(relativePath: { eq: "reviews/stories/watch.jpg" }) {
        ...successStory
      }
      DiamondHorse: file(relativePath: { eq: "reviews/stories/diamond_horse.jpg" }) {
        ...successStory
      }
      RingBox: file(relativePath: { eq: "reviews/stories/ring_box.jpg" }) {
        ...successStory
      }
    }
  `)
  return (
    <SectionBackground
      className={[common.margin0auto, common.dFlex, common.flexCol, common.flexAlignCenter, common.overflowHidden]}
      bgPattern={bgPattern}
      bgColor={GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]}
      height="fit-content"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      <SectionContainer>
        <StoriesTitle className={[common.textCenter]}>Seller Success Stories</StoriesTitle>
        <StoriesContainer className={[common.dFlex]}>
          {storiesData.map(story => (
            <StoryCard
              className={[common.pointer]}
              key={story.text}
              data-automation={`reviews-storyCard-${story.picture}`}
              onClick={() => {
                GA.successStories(story.text)
                window.open(story.readMoreLink, '_blank')
              }}
            >
              <Img imgStyle={{ width: '442px' }} alt={story.text} fluid={qData[story.picture].childImageSharp.fluid} />
              <TextContainer>
                <StoryTitle>{story.text}</StoryTitle>
                <ReadMore className={[common.pointer]}>Read More</ReadMore>
              </TextContainer>
            </StoryCard>
          ))}
          {/* div below for right side padding */}
          <div />
        </StoriesContainer>
      </SectionContainer>
    </SectionBackground>
  )
}

export default SuccessStories
