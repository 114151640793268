import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import common from '../../styles/common.module.css'
import { H2asH1, RunningText } from '../../styles/styled/commonText'
import { YoutubePopup } from '../homepage/testimonials/YoutubePopup'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'

import {
  SpacingSizes,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  LineHeights,
} from '../../../clarity'
import { to, from, size } from '../../styles/constants'

import play from '../../images/homepage/testimonials/play.svg'
import leftArrow from '../../images/icons/left_arrow.svg'
import rightArrow from '../../images/icons/right_arrow.svg'
import { GA } from '../../data/GA'

export interface Testimony {
  name: string
  quote: string
  videoId: string
  avatar: string
}

export const testimonialsData: Array<Testimony> = [
  {
    name: 'Amanda',
    quote: '“Worthy was helpful in selling my ring quickly.”',
    videoId: 'EAFBsdl52bE',
    avatar: 'Amanda',
  },
  {
    name: 'Elise',
    quote: '“They were great and made everything super easy.”',
    videoId: '2IdqATSGdts',
    avatar: 'Elise',
  },
  {
    name: 'Yasmin',
    quote: '“They make it so simple, I got my money very quickly.”',
    videoId: '1ayQhXhY_UE',
    avatar: 'Yasmin',
  },
  {
    name: 'Siera',
    quote: '“With the money I was able to go back to school.”',
    videoId: 'Xaog-fxsg5g',
    avatar: 'Siera',
  },
  {
    name: 'Keri',
    quote: '“It was pretty cool and I’ll use Worthy again!”',
    videoId: '41TLtrRrbeA',
    avatar: 'Keri',
  },
  {
    name: 'Vinnie',
    quote: '“I was able to use that money to pay for my income tax.”',
    videoId: 'j9x2Wyv8e3I',
    avatar: 'Vinnie',
  },
  {
    name: 'Sydnie',
    quote: '“I just sold my ring and was amazed at how easy it was.”',
    videoId: 'H4DFmjhPNEU',
    avatar: 'Sydnie',
  },
  {
    name: 'Sherman',
    quote: '“I enjoyed the experience. The auction was really exciting.”',
    videoId: 'Gmibmp6CgXY',
    avatar: 'Sherman',
  },
  {
    name: 'Stephanie',
    quote: '“We had a great experience. They were very trustworthy.”',
    videoId: 'GGfyif3Ygnc',
    avatar: 'Stephanie',
  },
  {
    name: 'Shari',
    quote: '“I will be using Worthy again if I have more jewelry to sell.”',
    videoId: 'wk6rCHrMOm8',
    avatar: 'Shari',
  },
  {
    name: 'Sabrina',
    quote: '“I was hesitant but they were wonderful from the beginning.”',
    videoId: 'CMQbFaEzwTA',
    avatar: 'Sabrina',
  },
  {
    name: 'Suzy',
    quote: '“The process was smooth, quick and professional.”',
    videoId: 'zQM3NuaGpbs',
    avatar: 'Suzy',
  },
  {
    name: 'Marc',
    quote: '“I got a good price and I’m very happy with their services.”',
    videoId: 'EqAca-zugsc',
    avatar: 'Marc',
  },
  {
    name: 'Rebecca',
    quote: '“I felt very comfortable with the process at Worthy.”',
    videoId: 'cdqzak3jJxc',
    avatar: 'Rebecca',
  },
  {
    name: 'Paris',
    quote: '“It was a wonderful experience, I would tell anybody to do it.”',
    videoId: 'muiLK1PKadQ',
    avatar: 'Paris',
  },
]

const TestimoniesTitle = fadeUpWrapper(styled(H2asH1)`
  line-height: ${LineHeights[54]}; // make sure this override is neccesarry with design
  margin-bottom: ${SpacingSizes.xl};

  @media ${to.bpC} {
    padding: 0 ${SpacingSizes.smd};
  }
`)

const TestimoniesContainer = fadeUpWrapper(styled.div`
  max-width: ${size.e};
  position: relative;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media ${from.bpC} {
    grid-template-columns: 59px ${SpacingSizes.md} auto 59px;
    gap: 0px;

    &::after {
      position: absolute;
      right: 59px;
      top: 0px;
      height: 100%;
      width: ${SpacingSizes.md};
      background-color: white;
      z-index: 2;
      content: ' ';
    }
  }
`)

const SliderWrapper = styled.div`
  gap: ${SpacingSizes.md};
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;

  @media ${to.bpC} {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
  }
`

const Slider = styled.div`
  width: 300%;
  display: grid;
  grid-template-columns: repeat(15, 1fr) 1px;
  grid-gap: 30px;
  @media ${to.bpC} {
    padding-left: ${SpacingSizes.smd};
  }
`

const ArrowContainer = styled.div`
  width: 59px;
  height: 59px;
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};
  border-radius: 30px;
  border: solid 1px ${GENERAL_COLOR_VALUES[GeneralColorNames.gray_201]};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
  @media ${to.bpC} {
    display: none;
  }
`

const SectionContainer = styled.div`
  max-width: ${size.e};
  padding: 0 ${SpacingSizes.md} ${SpacingSizes.xxl};

  @media ${to.bpC} {
    align-items: flex-start;
    padding: 0 0 ${SpacingSizes.xxl} 0;
  }
`

const TestimonyCard = styled.div`
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};

  @media ${to.bpC} {
    width: 216px;
  }
`

const TextContainer = styled.div`
  padding-bottom: ${SpacingSizes.x3s};
`

const TestimonyAvatar = styled.div`
  max-width: 110px;
`

const PlayIcon = styled.img``

const QuoteBox = styled.div`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_16_italic]}; // make sure this override is neccesarry with design
  line-height: ${LineHeights[24]};
  padding: ${SpacingSizes.sm} 0 ${SpacingSizes.xs};
`

const TestimonyName = styled(RunningText)`
  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.gray_501]};
`

const ArrowImg = styled.img`
  position: relative;
`

const Testimonials = () => {
  const [showVideo, setShowVideo] = useState(false)
  const [testimonyId, setTestimonyId] = useState('PGm08xmfkcY')
  const sliderRef = useRef<HTMLInputElement>(null)

  const qData = useStaticQuery(graphql`
    fragment reviewTestimony on File {
      childImageSharp {
        fluid(maxWidth: 110) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query ReviewTestimonyImageList {
      Amanda: file(relativePath: { eq: "reviews/testimonials/Amanda.png" }) {
        ...reviewTestimony
      }
      Elise: file(relativePath: { eq: "reviews/testimonials/Elise.png" }) {
        ...reviewTestimony
      }
      Yasmin: file(relativePath: { eq: "reviews/testimonials/Yasmin.png" }) {
        ...reviewTestimony
      }
      Siera: file(relativePath: { eq: "reviews/testimonials/Sierra.png" }) {
        ...reviewTestimony
      }
      Keri: file(relativePath: { eq: "reviews/testimonials/Keri.png" }) {
        ...reviewTestimony
      }
      Vinnie: file(relativePath: { eq: "reviews/testimonials/Vinnie.png" }) {
        ...reviewTestimony
      }
      Sydnie: file(relativePath: { eq: "reviews/testimonials/Sydnie.png" }) {
        ...reviewTestimony
      }
      Sherman: file(relativePath: { eq: "reviews/testimonials/Sherman.png" }) {
        ...reviewTestimony
      }
      Stephanie: file(relativePath: { eq: "reviews/testimonials/Stephanie.png" }) {
        ...reviewTestimony
      }
      Shari: file(relativePath: { eq: "reviews/testimonials/Shari.png" }) {
        ...reviewTestimony
      }
      Sabrina: file(relativePath: { eq: "reviews/testimonials/Sabrina.png" }) {
        ...reviewTestimony
      }
      Suzy: file(relativePath: { eq: "reviews/testimonials/Suzy.png" }) {
        ...reviewTestimony
      }
      Marc: file(relativePath: { eq: "reviews/testimonials/Marc.png" }) {
        ...reviewTestimony
      }
      Rebecca: file(relativePath: { eq: "reviews/testimonials/Rebecca.png" }) {
        ...reviewTestimony
      }
      Paris: file(relativePath: { eq: "reviews/testimonials/Paris.png" }) {
        ...reviewTestimony
      }
    }
  `)
  return (
    <>
      <SectionContainer className={[common.margin0auto, common.dFlex, common.flexCol, common.flexAlignCenter]}>
        <TestimoniesTitle className={[common.textCenter]}>Video Testimonials</TestimoniesTitle>
        <TestimoniesContainer className={[common.dGrid]}>
          <ArrowContainer
            className={['left']}
            onClick={() => {
              if (sliderRef.current) {
                const dLeft = sliderRef.current?.scrollLeft
                if (dLeft && dLeft > sliderRef.current.offsetWidth / 5) {
                  sliderRef.current.scrollLeft = dLeft - sliderRef.current.offsetWidth / 5
                } else {
                  sliderRef.current.scrollLeft = 0
                }
              }
            }}
          >
            <ArrowImg src={leftArrow} alt="previous slide" style={{ left: `-${SpacingSizes.x3s}` }} />
          </ArrowContainer>
          {/* the div below is a spacer so that the slider width can be 300% and fit exactly */}
          <div />
          <SliderWrapper ref={sliderRef}>
            <Slider>
              {testimonialsData.map(testimony => (
                <TestimonyCard
                  key={testimony.name}
                  className={[common.pointer]}
                  onClick={() => {
                    GA.testimonials(testimony.name)
                    setTestimonyId(testimony.videoId)
                    setShowVideo(true)
                  }}
                >
                  <TestimonyAvatar
                    className={[common.dInlineBlock, common.posRel, common.w100]}
                    src={testimony.avatar}
                    alt={testimony.name}
                  >
                    <Img alt={testimony.name} fluid={qData[testimony.name].childImageSharp.fluid} />
                    <PlayIcon className={[common.posAbs, common.bottomRight]} alt="play icon" src={play} />
                  </TestimonyAvatar>
                  <TextContainer>
                    <QuoteBox>{testimony.quote}</QuoteBox>
                    <TestimonyName>{testimony.name}</TestimonyName>
                  </TextContainer>
                </TestimonyCard>
              ))}
              <div />
            </Slider>
          </SliderWrapper>
          <ArrowContainer
            className={['right']}
            onClick={() => {
              if (sliderRef.current) {
                const dLeft = sliderRef.current?.scrollLeft
                if (dLeft && dLeft > 2450) {
                  sliderRef.current.scrollLeft = 2450
                } else {
                  sliderRef.current.scrollLeft = dLeft + sliderRef.current.offsetWidth / 5
                }
              }
            }}
          >
            <ArrowImg src={rightArrow} alt="next slide" style={{ left: `${SpacingSizes.x3s}` }} />
          </ArrowContainer>
        </TestimoniesContainer>
      </SectionContainer>
      {showVideo && <YoutubePopup onClose={() => setShowVideo(false)} videoId={testimonyId} />}
    </>
  )
}

export default Testimonials
