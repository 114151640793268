import styled from 'styled-components';
import { SpacingSizes } from '../../../clarity';
import { to } from '../../styles/constants'

export const BackgroundSection = styled.div`
  height: ${(props: any) => (props.height ? props.height : '295px')};
  background-image: ${(props: any) => (props.bgPattern ? `url(${props.bgPattern})` : null)};
  background-color: ${(props: any) => props.bgColor};
  background-position: ${(props: any) => props.bgPosition};
  background-repeat: ${(props: any) => props.bgRepeat};
  padding: 0;

  @media ${to.bpB} {
    height: auto;
    padding: ${SpacingSizes.xl} 0;
  }
`
