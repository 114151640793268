import { Helmet } from 'react-helmet'
import React from 'react'
import {
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
} from '../../../clarity/index';
// `isLive` stands for non-moderated reviews;
// if false - only preselected reviews will be shown
interface TrustPilotProps {
  isLive?: boolean,
  templateId: string,
  theme?: string,
  height?: string,
  stars?: string,
}

const TrustPilot = (tpProps:TrustPilotProps) => {
  const tpWidgetProps = {
    'data-locale': 'en-US',
    'data-template-id': tpProps.templateId,
    'data-businessunit-id': '570d40500000ff00058b6dbe',
    'data-style-height': tpProps.height,
    'data-style-width': '100%',
    'data-theme': tpProps.theme,
    'data-font-family': 'Roboto',
    'data-text-color': GENERAL_COLOR_VALUES[GeneralColorNames.black_main],
    'data-stars': tpProps.stars || null,
  }
  // @ts-ignore
  const alreadyLoaded = Boolean(typeof window !== 'undefined' && window.Trustpilot)
  if (!tpProps.isLive) {
    // @ts-ignore
    tpWidgetProps['data-tags'] = 'SelectedReview'
  }

  return (
    <>
      <Helmet>
        { alreadyLoaded ? null : <script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async /> }
      </Helmet>
      <div
        className="trustpilot-widget"
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...tpWidgetProps}
      >
        <a
          style={{ color: 'white' }}
          href="https://www.trustpilot.com/review/worthy.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}

TrustPilot.defaultProps = {
  isLive: Boolean(process.env.TRUST_PILOT_REVIEWS_LIVE || true),
  theme: 'light',
}

export default TrustPilot
