import React from 'react'
import styled from 'styled-components'
import common from '../../styles/common.module.css'
import { BoldRunningText, BlueWord, H3 } from '../../styles/styled/commonText'
import { envUrls } from '../../data/objects/env'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import { SpacingSizes, GENERAL_COLOR_VALUES, GeneralColorNames, MediumPrimaryButton } from '../../../clarity'

import { size, to } from '../../styles/constants'
import { useBP } from '../../data/objects/breakpoints'
import { GA } from '../../data/GA'

const recommendedLinks = [
  {
    key: 'faqs',
    label: 'FAQs',
    url: `/faqs/`,
  },
  {
    key: 'reviews',
    label: 'Reviews',
    url: '/reviews/',
  },
  {
    key: 'recently-sold',
    label: 'Recently sold',
    url: `${envUrls.site}/about/recent-deals/rings`,
  },
  {
    key: 'how-it-works',
    label: 'How it works',
    url: '/how-it-works/',
  },
]

const LinksContainer = fadeUpWrapper(styled.div`
  margin: ${SpacingSizes.xxl} 0;

  @media ${to.bpC} {
    flex-wrap: wrap;
    padding: 0 ${SpacingSizes.smd};
    gap: ${SpacingSizes.sm};
    margin: ${SpacingSizes.xxl} auto;
  }

  @media ${to.bpB} {
    flex-wrap: wrap;
    padding: 0 ${SpacingSizes.smd};
    gap: ${SpacingSizes.sm};
    margin: ${SpacingSizes.xxl} auto;
    max-width: 400px;
  }
`)
const LinksTitle = styled(H3)`
  margin-right: ${SpacingSizes.xlg};

  @media ${to.bpC} {
    margin-bottom: ${SpacingSizes.xs};
    margin-right: 0;
    width: 100%;
    text-align: center;
  }
`

const ButtonsPaddingMobile = `9px ${SpacingSizes.smd}`
const ButtonsPaddingDesktop = `${SpacingSizes.xxs} ${SpacingSizes.smd}`

const LinkButton = styled.button`
  border: none;
  border-radius: 3px;
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};
  padding: ${ButtonsPaddingDesktop};
  margin-right: ${SpacingSizes.md};
  @media (min-width: ${size.b}) and (max-width: ${size.c}) {
    margin-right: 0;
  }
  @media ${to.bpB} {
    margin: 0;
    padding: ${ButtonsPaddingMobile};
  }
`

const RecommendedLinks = ({
  relevantLinks,
  page,
  dataAutomationPrefix,
}: {
  relevantLinks?: Array<string>
  page: 'FAQs page' | 'How it Works page' | 'Reviews'
  dataAutomationPrefix: string
}) => {
  const { fitsB } = useBP().get()

  let filtered = recommendedLinks
  if (relevantLinks) {
    filtered = recommendedLinks.filter(recommended => {
      return relevantLinks.some(relevant => {
        return relevant === recommended.label
      })
    })
  }
  return (
    <LinksContainer className={[common.dFlex, common.flexJustifyCenter, common.flexAlignCenter]}>
      <LinksTitle>Recommended for you</LinksTitle>
      {filtered.map(link => {
        return (
          <LinkButton
            key={link.key}
            className={[common.pointer, common.border0]}
            data-automation={`${dataAutomationPrefix}-RecommendedLinks-${link.key}`}
            onClick={() => {
              GA.recommendedLink(page, link.label, link.url)
            }}
          >
            <BoldRunningText>
              <BlueWord>{link.label}</BlueWord>
            </BoldRunningText>
          </LinkButton>
        )
      })}
      <MediumPrimaryButton
        overrideCSS={{
          width: '100%',
          height: fitsB ? SpacingSizes.xlg : SpacingSizes.lg,
        }}
        click={stopAnimation => {
          stopAnimation && stopAnimation()
          GA.recommendedCTA(page)
        }}
        text="Get Started"
      />
    </LinksContainer>
  )
}
export default RecommendedLinks
