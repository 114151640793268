import React from 'react';
import styled from 'styled-components';
import common from '../../styles/common.module.css'
import bgPattern from '../../images/HIW/HIW_pattern.svg'
import { Mega, LargeRunningText } from '../../styles/styled/commonText'
import { BackgroundSection } from '../commonStyled/sectionsWithBackground'
import { SpacingSizes, GENERAL_COLOR_VALUES, GeneralColorNames } from '../../../clarity';
import { to } from '../../styles/constants'
import { useBP } from '../../data/objects/breakpoints'
import { renderOnMount } from '../forceUpdate'
import { HEADER_ANIMATION_SCROLL_REF } from "../homepage/submissionSelectionSection/submissionSelectionDesktop";

interface PageTitleProps {
  title: string;
  subTitle: string;
}

const Section = styled(BackgroundSection)`
  @media ${to.bpB} {
    margin-top: ${SpacingSizes.xl};
    padding: ${SpacingSizes.xl} ${SpacingSizes.smd}
  }
`
const MainTitle = styled(Mega)`
  margin-bottom: ${SpacingSizes.xs};
`

const SubTitle = styled(LargeRunningText)`
  max-width: 750px;
  @media ${to.bpB} {
    max-width: 370px;
  }
`

const PageTitle = ({ title, subTitle }:PageTitleProps) => {
  const renderKey = renderOnMount()
  return (
    <Section
      ref={HEADER_ANIMATION_SCROLL_REF}
      className={[
        common.dFlex,
        common.flexCol,
        common.flexAlignCenter,
        common.flexJustifyCenter,
        useBP().get().fitsB && common.textCenter,
      ]}
      bgPattern={bgPattern}
      bgColor={GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]}
      bgPosition="center"
      bgRepeat="no-repeat"
      key={renderKey}
    >
      <MainTitle>
        {title}
      </MainTitle>
      <SubTitle className={[common.textCenter]}>
        {subTitle}
      </SubTitle>
    </Section>
  )
}
export default PageTitle
