import React from 'react';
import styled from 'styled-components';
import { SpacingSizes } from '../../../clarity';
import TrustPilot from '../homepage/trustPilot'
import { useBP } from '../../data/objects/breakpoints';
import { renderOnMount } from '../forceUpdate'
import { size, to } from '../../styles/constants'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'

const templateID = '539adbd6dec7e10e686debee'

const SectionContainer = fadeUpWrapper(styled.div`
  max-width: ${size.e};
  padding: 0px ${SpacingSizes.md};
  margin: ${SpacingSizes.xxl} auto 0;

  @media ${to.bpB} {
    padding: 0px ${SpacingSizes.smd};
  }
`)

const TrustPilotSection = () => {
  const renderKey = renderOnMount()
  const { fitsB } = useBP().get()
  return (
    <SectionContainer key={renderKey} data-automation="reviews-section-trustPilot">
      <TrustPilot templateId={templateID} height={fitsB ? '520px' : '700px'} stars="4,5" />
    </SectionContainer>
  )
}
export default TrustPilotSection
