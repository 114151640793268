import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import common from '../../styles/common.module.css'
import { H2asH1, H3, RunningText } from '../../styles/styled/commonText'
import {
  SpacingSizes,
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  LineHeights,
} from '../../../clarity'
import { to, size, from } from '../../styles/constants'
import { UnderlineSecondaryButton } from '../../styles/styled/commonStyled'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'
import { GA } from '../../data/GA'

export interface Expert {
  name: string
  title: string
  review: string
  readMoreLink: string
  avatar: string
}

const expertsData: Array<Expert> = [
  {
    name: 'Mandy Walker',
    title: 'Since My Divorce',
    review:
      '“Worthy was created to help people just like you sell their engagement rings without the drawbacks that come with the other options.”',
    readMoreLink: 'https://sincemydivorce.com/',
    avatar: 'Mandy',
  },
  {
    name: 'Jewelry Shopping Guide',
    title: 'An online  guide for all things jewelry related',
    review:
      '“Worthy often finds a much better deal for your piece of jewelry than any of their competitors, online or off.”',
    readMoreLink: 'https://www.jewelryshoppingguide.com/selling-your-diamond-worthy-review/',
    avatar: 'JewelryShoppingGuide',
  },
  {
    name: 'Grant Sabatier',
    title: 'Millennial Money',
    review: '“Worthy is a great platform. All you need is a bit of time and patience.”',
    readMoreLink: 'https://millennialmoney.com/worthy-review/',
    avatar: 'Grant',
  },
]

const SectionContainer = styled.div`
  max-width: ${size.e};
  padding: ${SpacingSizes.xxl} ${SpacingSizes.md};

  @media ${to.bpB} {
    align-items: flex-start;
    padding: ${SpacingSizes.xxl} 0 ${SpacingSizes.xxl} 0;
  }
`

const ExpertsContainer = fadeUpWrapper(styled.div`
  grid-gap: ${SpacingSizes.md};

  @media ${from.bpB} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${to.bpB} {
    width: 100%;
    grid-template-columns: repeat(3, 1fr) 1px;
    overflow-x: auto;
    padding: 0px ${SpacingSizes.smd};

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
  }
`)

const ExpertsTitle = fadeUpWrapper(styled(H2asH1)`
  line-height: ${LineHeights[54]}; // make sure this override is neccesarry with design
  margin-bottom: ${SpacingSizes.xl};

  @media ${to.bpB} {
    padding: 0px ${SpacingSizes.smd};
  }
`)

const ExpertsReview = styled.div`
  @media ${from.bpB} {
    display: grid;
  }
  @media ${to.bpB} {
    width: 310px;
    flex-flow: column;
  }
`

const ExpertAvatar = styled.div`
  max-width: 85px;
  min-width: 85px;
  border-radius: 50%;
  box-shadow: 0 0 4px 2px ${GENERAL_COLOR_VALUES[GeneralColorNames.gray_300]};

  @media ${to.bpB} {
    min-width: 90px;
    margin-right: 24px;
  }
}
`

const ExpertName = styled(H3)`
  line-height: ${LineHeights[30]};
`

const ExpertTitle = styled(RunningText)`
  line-height: ${LineHeights[24]};

  @media ${to.bpB} {
    padding-right: ${SpacingSizes.smd};
  }
`

const InfoContainer = styled.div`
  padding-top: ${SpacingSizes.smd};
  display: grid;
  grid-template-columns: 95fr 300fr;
  align-content: end;
  align-items: center;

  @media ${to.bpB} {
    padding-top: ${SpacingSizes.sm};
    min-height: 125px;
  }
`

const QuoteBox = styled.div`
  display: grid;
  align-content: space-between;
  min-height: 190px;
  margin-top: ${SpacingSizes.mdl};
  padding: ${SpacingSizes.smd} ${SpacingSizes.md} ${SpacingSizes.md} ${SpacingSizes.smd};
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_18_italic]}; // make sure this override is neccesarry with design
  line-height: ${LineHeights[24]};
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.primary_100]};

  @media ${to.bpB} {
    min-height: 230px;
  }
`

const ReadMore = styled(UnderlineSecondaryButton)`
  width: 105px;
  margin-top: ${SpacingSizes.smd};
`

const MobileSpacer = styled.div`
  @media ${from.bpB} {
    display: none;
  }
`

const ExpertsReviews = () => {
  const qData = useStaticQuery(graphql`
    fragment expert on File {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query ExpertImageList {
      JewelryShoppingGuide: file(relativePath: { eq: "reviews/experts/JewelryShoppingGuide.png" }) {
        ...expert
      }
      Grant: file(relativePath: { eq: "reviews/experts/Grant.png" }) {
        ...expert
      }
      Mandy: file(relativePath: { eq: "reviews/experts/MandyLogo.png" }) {
        ...expert
      }
    }
  `)
  return (
    <SectionContainer className={[common.margin0auto, common.dFlex, common.flexCol, common.flexAlignCenter]}>
      <ExpertsTitle className={[common.textCenter]}>Experts Review Worthy</ExpertsTitle>
      <ExpertsContainer className={[common.dGrid]}>
        {expertsData.map(expert => (
          <ExpertsReview key={expert.name} className={[common.dFlex, common.w100]}>
            <InfoContainer className="row w100">
              <div className="flexCol">
                <ExpertAvatar>
                  <Img
                    // imgStyle={{ width: '100%' }}
                    alt={expert.name}
                    fluid={qData[expert.avatar].childImageSharp.fluid}
                  />
                </ExpertAvatar>
              </div>
              <div className="flexCol">
                <ExpertName>{expert.name}</ExpertName>
                <ExpertTitle>{expert.title}</ExpertTitle>
              </div>
            </InfoContainer>
            <QuoteBox className={[common.dFlex, common.flexCol]}>
              {expert.review}
              <ReadMore
                data-automation={`reviews-experts-${expert.avatar}-readMoreButton`}
                onClick={() => {
                  GA.expertsReview(expert.name)
                  window.open(expert.readMoreLink)
                }}
              >
                Read More
              </ReadMore>
            </QuoteBox>
          </ExpertsReview>
        ))}
        {/* div below for adding space at the end of the grid */}
        <MobileSpacer />
      </ExpertsContainer>
    </SectionContainer>
  )
}
export default ExpertsReviews
